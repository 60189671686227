import dayjs from "dayjs"
import { graphql, Link } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import parse from "html-react-parser"
import React from "react"
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
} from "react-share"
import readingTime from "reading-time"
import Announcement from "../components/Announcement/Announcement"
import Footer from "../components/Footer/footer"
import Header from "../components/header"
import Layout from "../components/layout.js"
import RelatedPostItems from "../components/RelatedPostItems/RelatedPostItems"
import Seo from "../components/seo"
import Table from "../components/Table/Table"
import { StoreContext } from "../store"
import isScrolling from "../utilities/isScrolling"
import tableOfContentHeaders from "../utilities/tableOfContentHeaders"
import tableOfContentsSidebar from "../utilities/tableOfContentsSidebar"
import tableOfContentsSmoothScroll from "../utilities/tableOfContentsSmoothScroll"
import tableOfContentsToggle from "../utilities/tableOfContentsToggle"
import videoLinkModal from "../utilities/videoCtaLinkModal"
import vwo from "../utilities/vwo"

const Blog = ({ location, data }) => {
  const {
    id,
    title,
    content,
    author,
    featuredImage,
    relatedPosts,
    date,
    tableOfContentsGraphQL,
  } = data.wpPost

  const disclaimer =
    data?.wp?.overviewBlog?.overviewBlog?.blogFooterContent?.content

  const vwoPages =
    data.wp?.overviewHubspot?.hubspotSettings?.vwoScriptLoading?.vwoPosts

  let currentVWOPage = null

  if (vwoPages !== null && vwoPages !== undefined) {
    currentVWOPage = vwoPages.filter(page => page.id === id)
  }

  const { viewPortWidth, isAnnouncementActive } = React.useContext(StoreContext)

  let authourName = ""

  if (author.node?.firstName && author.node?.lastName) {
    authourName = `${author.node.firstName}  ${author.node.lastName}`
  } else {
    authourName = author.node?.name
  }

  const authorInfo = author?.node?.authorInfo

  const authorPhoto = getImage(
    authorInfo?.photo?.localFile?.childImageSharp.gatsbyImageData
  )
  const authorPhotoAlt = authorInfo?.photo?.altText
  let authorLinkedinUrl = ""
  const authorLinkedin = author?.node?.overviewSocialMedia?.patternsSocialMedia
  if (authorLinkedin && authorLinkedin[0]) {
    authorLinkedinUrl = authorLinkedin[0]?.patternsSocialMediaLinkedinInUrl
  }

  const featImg = getImage(
    featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData
  )
  const featImgAlt = featuredImage?.node?.altText

  const postsRelated = relatedPosts?.blogRelatedPost
  let postsRelImgBg = ""
  let postsRelImg = ""
  let postsRelImgAlt = ""

  const toc = tableOfContentsGraphQL

  React.useEffect(() => {
    isScrolling()
    if (typeof window !== "undefined" && window.document) {
      tableOfContentHeaders()
      videoLinkModal()
      tableOfContentsToggle()
      tableOfContentsSmoothScroll()
      tableOfContentsSidebar(
        window.document.querySelector(".toc-main-content-wrapper"),
        false
      )
      window.addEventListener("scroll", () => {
        tableOfContentsSidebar(
          window.document.querySelector(".toc-main-content-wrapper"),
          false
        )
      })
    }

    window.addEventListener("resize", () => {
      tableOfContentHeaders()
    })

    if (currentVWOPage && currentVWOPage.length > 0) vwo()
  }, [])

  return (
    <Layout>
      <Seo
        pageData={data.wpPost}
        authorName={authourName}
        location={location}
      />

      <div className="post-template-default single single-post single-format-standard wp-embed-responsive is-blog has-sidebar customize-support js">
        <div
          className={`Page ${isAnnouncementActive ? "has-announcement" : ""} `}
        >
          <Announcement />
          <Header siteTitle={`GoodHire`} viewPortWidth={viewPortWidth} />

          <div className="Page-content">
            <section className="Body">
              <div className="Wrap">
                <div className="Grid with-12 has-xSmallGaps">
                  <div className="is-12">
                    <div className="Blog-header">
                      <div className="Grid with-2 has-xSmallGaps">
                        <div className="Blog-header-home is-2--small">
                          <h2>
                            <Link to="/blog/">Blog</Link>
                          </h2>
                        </div>
                        <div className="Blog-header-search hide-small"></div>
                      </div>
                    </div>
                  </div>

                  <div className="is-10 is-12--small">
                    <header>
                      <h1 className="Content-title">{title}</h1>
                    </header>
                  </div>

                  <div className="is-2 is-12--small">
                    <div className="social-sharex">
                      <ul
                        style={{
                          display: `flex`,
                          listStyle: `none`,
                          // paddingLeft: 8,
                          padding: 0,
                        }}
                      >
                        <li className="facebook share-button">
                          <FacebookShareButton
                            url={location.href}
                            quote={title}
                          >
                            <FacebookIcon size={41} round />
                          </FacebookShareButton>
                        </li>
                        <li className="linkedin share-button">
                          <LinkedinShareButton url={location.href}>
                            <LinkedinIcon size={41} round />
                          </LinkedinShareButton>
                        </li>
                        <li className="twitter share-button">
                          <TwitterShareButton url={location.href} title={title}>
                            <TwitterIcon size={41} round />
                          </TwitterShareButton>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="is-12">
                    <div className="Content-byline">
                      <div className="AuthorName">
                        <p>
                          <span className="Author-displayName">
                            {authourName}
                          </span>
                        </p>
                      </div>

                      <time dateTime={date} className="Content-date">
                        {dayjs(date).format("MMMM DD, YYYY")}
                      </time>

                      <div className="Readtime">
                        <p>
                          <span className="Readtime-display">
                            <i aria-hidden="true" className="fa fa-clock-o"></i>{" "}
                            {readingTime(content).text}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="Content-image is-12">
                    <GatsbyImage
                      style={{ width: "100%" }}
                      image={featImg}
                      alt={featImgAlt}
                    />
                  </div>

                  <div className="Grid with-12 has-xSmallGaps toc-main-content-wrapper">
                    <div className="is-4 is-12--small toc">
                      <div className="Blog-sidebar-toc">
                        <h3 className="Toc-header">Table of Contents</h3>
                        <div className="Blog-sidebar-toc-inner">
                          <p>Click a chapter to scroll directly to it.</p>
                          <hr />
                          {toc && parse(toc)}
                          <hr />
                        </div>
                      </div>
                    </div>

                    <div className="is-8 is-12--small">
                      <div className="Body-content">
                        {parse(content, {
                          replace: domNode => {
                            if (
                              domNode &&
                              domNode.name === "table" &&
                              !domNode?.attribs?.class?.includes("no-filter") &&
                              !domNode?.parent?.attribs?.class?.includes(
                                "no-filter"
                              )
                            ) {
                              return <Table tableDomNode={domNode}></Table>
                            }
                          },
                        })}

                        <hr className="Blog-author-divider" />

                        {authorInfo && authorInfo.bio && (
                          <>
                            <div className="Grid with-12 Blog-author">
                              <div
                                className="is-8 is-12--small"
                                style={
                                  !authorLinkedinUrl
                                    ? { paddingBottom: 48 }
                                    : {}
                                }
                              >
                                <h3
                                  style={
                                    viewPortWidth < 841 && !authorLinkedinUrl
                                      ? { paddingBottom: 30 }
                                      : {}
                                  }
                                >
                                  About the Author
                                </h3>
                              </div>
                              <div
                                className="is-4 is-12--small"
                                style={
                                  !authorLinkedinUrl
                                    ? { paddingBottom: 48 }
                                    : {}
                                }
                              >
                                <div className="SocialMedia">
                                  <p
                                    className={`follow ${
                                      authorLinkedinUrl ? "" : "empty"
                                    }`}
                                  >
                                    Follow Me
                                  </p>
                                  <ul>
                                    {authorLinkedinUrl ? (
                                      <li className="is-linkedin-in">
                                        <a
                                          href={authorLinkedinUrl}
                                          title="LinkedIn"
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          <svg
                                            aria-hidden="true"
                                            focusable="false"
                                            data-prefix="fab"
                                            data-icon="linkedin-in"
                                            role="img"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 448 512"
                                            className="svg-inline--fa fa-linkedin-in fa-w-14"
                                          >
                                            <path
                                              fill="currentColor"
                                              d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"
                                              className=""
                                            ></path>
                                          </svg>
                                          <span className="screen-reader-text">
                                            LinkedIn
                                          </span>
                                        </a>
                                      </li>
                                    ) : (
                                      <li className="is-">
                                        <Link
                                          to="/"
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          <span className="screen-reader-text"></span>
                                        </Link>
                                      </li>
                                    )}
                                  </ul>
                                </div>
                              </div>
                              <div className="is-3 is-12--small">
                                <div className="AuthorPhoto">
                                  <GatsbyImage
                                    image={authorPhoto}
                                    alt={authorPhotoAlt}
                                  />
                                </div>
                              </div>
                              <div className="is-9 is-12--small">
                                <div className="AuthorBio">
                                  {parse(authorInfo.bio)}
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="is-12">
                    <div className="Blog-sidebar-related">
                      <hr />
                      <h3>Keep Reading</h3>
                      {postsRelated &&
                        postsRelated.map(post => {
                          postsRelImg = getImage(
                            post.featuredImage?.node?.localFile?.childImageSharp
                              ?.gatsbyImageData
                          )

                          postsRelImgBg = convertToBgImage(postsRelImg)
                          postsRelImgAlt = post.featuredImage.node.altText

                          return (
                            <article
                              key={post.id}
                              className="Content post type-post status-publish format-standard has-post-thumbnail hentry category-background-checks"
                            >
                              <Link to={`/blog/${post.slug}/`}>
                                <div className="Content-thumbnail">
                                  <BackgroundImage
                                    Tag="div"
                                    {...postsRelImgBg}
                                    className="Content-thumbnail-image"
                                  ></BackgroundImage>
                                </div>
                                <div className="Content-text">
                                  <header className="Content-header">
                                    <h3 className="Content-title">
                                      {post.title}
                                    </h3>
                                  </header>

                                  <div className="Content-excerpt">
                                    {parse(post.excerpt)}
                                  </div>
                                </div>
                              </Link>
                            </article>
                          )
                        })}
                      {(postsRelated === null ||
                        postsRelated === undefined) && <RelatedPostItems />}
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {parse(disclaimer)}
          </div>
          <Footer />
        </div>
      </div>
    </Layout>
  )
}

export default Blog

export const query = graphql`
  query ($id: String!) {
    wpPost(id: { eq: $id }) {
      id
      title
      content
      date
      tableOfContentsGraphQL
      author {
        node {
          firstName
          lastName
          name
          overviewSocialMedia {
            patternsSocialMedia {
              ... on WpUser_Overviewsocialmedia_PatternsSocialMedia_LinkedinIn {
                fieldGroupName
                patternsSocialMediaLinkedinInUrl
              }
            }
          }
          authorInfo {
            bio
            company
            jobTitle
            companyLogo {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED)
                }
              }
            }
            photo {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED)
                }
              }
            }
          }
        }
      }
      featuredImage {
        node {
          altText
          sourceUrl
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData(width: 1232, placeholder: BLURRED)
            }
          }
          mediaDetails {
            height
            width
          }
        }
      }
      relatedPosts {
        blogRelatedPost {
          ... on WpPost {
            id
            title
            excerpt
            slug
            featuredImage {
              node {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(placeholder: BLURRED)
                  }
                }
              }
            }
          }
        }
      }
      seo {
        title
        metaDesc
        metaRobotsNoindex
        metaRobotsNofollow
        opengraphTitle
        opengraphDescription
        opengraphPublishedTime
        opengraphPublishedTime
        opengraphModifiedTime
        opengraphSiteName
        opengraphType
        readingTime
      }
      richSnippetSchemaJson
    }
    wp {
      overviewBlog {
        overviewBlog {
          blogFooterContent {
            ... on WpPage {
              id
              content
            }
          }
        }
      }
      overviewHubspot {
        hubspotSettings {
          vwoScriptLoading {
            vwoPosts {
              ... on WpPost {
                id
              }
            }
          }
        }
      }
    }
  }
`
